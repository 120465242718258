import * as React from "react";
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";

export const Text = ({ slice }) => (
  <div className="mt-8 max-w-7xl text-2xl">
    <div>
      <PrismicRichText field={slice.primary.text.richText} />
    </div>
  </div>
);

export const query = graphql`
  fragment PostDataBodyText on PrismicPostDataBodyText {
    primary {
      text {
        richText
      }
    }
  }
`;
