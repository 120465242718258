import * as React from "react";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react";

import { components } from "../slices";
import { Layout } from "../components/Layout";
import Navigation from "../components/Navigation";

const Post = ({ data }) => {
  if (!data) return null;

  const post = data.prismicPost.data;
  const title = post.title.text || "Untitled";
  const titleText = title.charAt(0).toUpperCase() + title.slice(1);
  const date = post.date;

  return (
    <Layout>
      <Navigation />
      <div className="flex flex-col px-4 ">
        <div className="container">
          <div className="post-header">
            <h1 className="text-blue-950 font-redhat uppercase font-extrabold text-5xl md:text-9xl">
              {titleText}
            </h1>
            <p className="text-sm">
              <time>{date}</time>
            </p>
          </div>
        </div>

        <SliceZone slices={post.body} components={components} />
      </div>
    </Layout>
  );
};
export default Post;

export const query = graphql`
  query BlogPostQuery($id: String) {
    prismicPost(id: { eq: $id }) {
      _previewable
      id
      uid
      lang
      type
      url
      data {
        date
        tags
        title {
          text
        }
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...PostDataBodyText
          ...PostDataBodyQuote
          ...PostDataBodyImageWithCaption
        }
        categories {
          category {
            document {
              ... on PrismicCategories {
                data {
                  name
                }
              }
            }
          }
        }
        header_image {
          url
        }
      }
    }
  }
`;
